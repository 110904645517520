<template>
  <div class="container mypage withTop">
    <section class="contents farmList growhList">
      <article class="inner" v-if="growthList.length > 0">
        <ul>
          <li v-for="(data, i) in growthList" :key="i" class="box">
            <router-link :to="`/growthDetail?id=${data._id}`">
              <h4>
                {{ data.crops }}
                <span class="sampleName">{{ data.sample }}</span>
                <span class="flr">{{ moment(data.registerDate).format("YYYY.MM.DD") }}</span>
              </h4>

              <table>
                <tr>
                  <th>초장</th>
                  <td>{{ data.totalLT }} cm</td>
                  <th>잎개수</th>
                  <td>{{ data.leafCount }} 개</td>
                </tr>
                <tr>
                  <th>잎길이</th>
                  <td>{{ data.leafLength }} cm</td>
                  <th>잎너비</th>
                  <td>{{ data.leafWidth }} cm</td>
                </tr>
              </table>
            </router-link>
          </li>
        </ul>
        <button class="moreBtn" @click="handleMore" v-show="growthList.length < growthTotal">
          더보기
          <span class="material-icons">add</span>
        </button>
      </article>
      <Nodata v-else />
    </section>
    <router-link to="/growthRegister" tag="button" class="floatingBtn">
      <span class="material-icons-round">add</span>
    </router-link>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import Nodata from "../../components/contents/Nodata.vue";
export default {
  data() {
    return {
      moment: moment,
      list: [],
      farmer: "",
      growthUserList: [],
      currentPage: 1,
      total: 0,

      result: 0,
      remainder: 0,
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.farmer = this.$route.query.id;
    }
    this.getGrowthList();
  },
  computed: {
    ...mapState(["userId", "growthList", "growthTotal", "mainFarm"]),
  },

  created() {
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
    this.$store.dispatch("SET_PAGE_NAME", "생육데이터관리");
  },
  methods: {
    handleMore() {
      this.result = this.growthList.length / 10;
      this.remainder = this.growthList.length % 10;
      if (this.remainder == 0) {
        this.currentPage = this.result + 1;
      } else {
        this.currentPage = this.result;
      }
      this.getGrowthList();
    },
    getGrowthList() {
      if (this.farmer == "") {
        this.farmer = this.mainFarm.farmer._id;
        this.currentPage = 1;
        this.remainder = this.growthList.length % 10;
      }

      let data = {
        farmer: this.farmer,
        page: this.currentPage,
        remainder: this.remainder,
      };
      this.$store.dispatch("getGrowthList", data);
    },
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
  },
  components: { Nodata },
};
</script>
